<template lang="pug">
Fragment
  router-view(v-if="$route.params.slug")
  .chooser-use-cases(v-else)
    .chooser-use-cases-cards
      template(v-if="isUseCaseLoading")
        .chooser-use-cases-card(v-for="index in 5" :key="index")
          vue-skeleton-loader.mb-5(type="rect" width="320px" height="205px")
          vue-skeleton-loader(type="rect" width="320px" height="59px")
      .chooser-use-cases-card(
        v-else
        v-for="useCase in sortedByScore"
        v-if="useCase.templates.length > 0"
        :id="useCase._id"
        :class="{ 'special-case': specialCaseIds.includes(useCase._id) }"
      )
        UseCaseCard(
          :image="useCaseImageById(useCase._id)"
          :title="localizedUseCase(useCase._id).name"
          :description="localizedUseCase(useCase._id).description"
          @click="handleUseCaseClick(useCase)"
        )
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import { Fragment } from 'vue-frag';
  import UseCaseCard from '@/components/TemplateChooser/components/cards/UseCaseCard.vue';

  const { mapGetters: useCaseGetter } = createNamespacedHelpers('useCase');

  export default {
    components: {
      Fragment,
      UseCaseCard,
    },
    data() {
      return {
        specialCaseIds: ['64c2cc4e957a9c002eac19e3', '65ba19dc39eb6000240f7e02'],
      };
    },
    computed: {
      ...useCaseGetter([
        'useCases',
        'isUseCaseLoading',
        'useCaseImageById',
        'localizedUseCase',
        'sortedByScore',
      ]),
    },
    methods: {
      handleUseCaseClick(useCase) {
        this.$router.push({
          name: 'chooser-use-case',
          params: {
            slug: useCase.slug,
          },
        });
      },
    },
  };
</script>

<style lang="sass">
  .special-case
    img
      padding: 0 !important
  .chooser-use-cases
    padding-bottom: 5rem
    &-title
      font-weight: bold
      font-size: 2.25rem
      padding-bottom: 1.75rem
    &-cards
      display: grid
      grid-template-columns: 1fr 1fr 1fr
      column-gap: 1.5rem
      row-gap: 1.25rem
      @media screen and (max-width: 1399px)
        grid-template-columns: 1fr 1fr
      @media screen and (max-width: 767px)
        grid-template-columns: 1fr
</style>
