<template lang="pug">
.use-case-card(@click="$emit('click')")
  .use-case-card-image-wrap
    img.use-case-card-image(:src="image")
  .use-case-card-content
    .use-case-card-title(v-html="title")
    .use-case-card-description(v-html="description")
</template>
<script>
  export default {
    props: {
      image: { type: String, required: true },
      title: { type: String, required: true },
      description: { type: String, required: true },
    },
  };
</script>
<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  p
    margin-top: unset
    margin-bottom: unset
  .use-case-card
    width: 100%
    height: 100%
    border-radius: 0
    cursor: pointer
    @media (pointer: fine) and (hover: hover)
      &:hover
        img
          transform: scale(1.06)
        .use-case-card-title
          color: $om-orange
    img
      transition: all 0.3s
    &-image-wrap
      overflow: hidden
      border-radius: 8px

    .use-case-card-image
      width: 100%
      height: auto
      aspect-ratio: 16 / 11
      object-fit: contain
      background: #FFF7F2
      padding: 2vw 0
      @media screen and (max-width: 991px)
        padding: 20px 0
    .use-case-card-content
      padding: 12px 0
      .use-case-card-title
        font-size: 1.125rem
        font-weight: 700
        transition: all 0.3s
      .use-case-card-description
        font-size: 0.75rem
        color: $om-dark-grey-3
        min-height: 2.25rem
      .use-case-card-learn-more-section
        display: flex
        justify-content: flex-end
</style>
